<script>
import { mapActions } from 'vuex'

export default {
  name: 'GroupsList',
  components: {
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    Dropdown: () => import('@/components/general/Dropdown'),
    Datatable: () => import('@/components/general/Datatable'),
    FilterList: () => import('@/components/general/FilterList'),
    Pagination: () => import('@/components/general/Pagination'),
    SelectField: () => import('@/components/general/SelectField'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FilterListSearch: () => import('@/components/general/FilterListSearch')
  },

  data () {
    return {
      pagination: {
        page: 1,
        lastPage: null,
        filter: {},
        limit: 12
      },

      groupId: null,
      filterModalOpen: false,
      modalConfirmDeactivateGroupActive: false,

      filterOptions: [
        {
          text: this.$t('solutions.manage:filtered-form.input.1.option.1'),
          value: 'true'
        },
        {
          text: this.$t('solutions.manage:filtered-form.input.1.option.2'),
          value: 'false'
        }
      ]
    }
  },

  computed: {
    groupsList () {
      return this.$store.getters.groupsList
    },

    canWrite () {
      return this.getContextPermission('groups') === 'write'
    }
  },

  watch: {
    'pagination.page' () {
      this.getGroupList()
    }
  },

  created () {
    this.getGroupList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetGroupsList',
      'attemptRemoveGroup',
      'attemptToggleActiveGroup'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    hasAnyConnections (group) {
      if ((group.groupUser && group.groupUser.length > 0) ||
          (group.groupSolution && group.groupSolution.length > 0) ||
          (group.groupLearningPath && group.groupLearningPath.length > 0) ||
          (group.groupProgram && group.groupProgram.length > 0) ||
          (group.groupThematicPage && group.groupThematicPage.length > 0)) {
        return true
      } else {
        return false
      }
    },

    getGroupList () {
      this.setFetching(true)

      this.pagination.order = {
        active: 'desc',
        name: 'asc'
      }

      this.attemptGetGroupsList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
      }).finally(() => {
        this.setFetching(false)
      })
    },

    createNewGroup () {
      this.$router.push({ name: 'groups.manage' })
    },

    searchGroup (search) {
      this.pagination.page = 1

      this.pagination.query = {
        name: search
      }

      this.getGroupList()
    },

    edit (groupId, tab = 1) {
      this.$router.push({
        name: 'groups.manage',
        params: {
          id: groupId,
          tab: tab
        }
      })
    },

    checkGroupToggle (group) {
      this.groupId = group.id

      if (group.status === true && this.hasAnyConnections(group)) {
        this.openDeactivateGroupModal()
      } else {
        this.toggleActive()
      }
    },

    toggleActive () {
      this.setFetching(true)

      this.attemptToggleActiveGroup(this.groupId).then((response) => {
        this.setFeedback({
          message: response.data.active
            ? this.$t('groups:feedback.toggle.activate.success')
            : this.$t('groups:feedback.toggle.deactivate.success')
        })

        this.getGroupList()
      }).catch(() => {
        this.setFeedback({ message: this.$t('global.error') })
      }).finally(() => {
        this.closeDeactivateGroupModal()
        this.setFetching(false)
      })
    },

    openConnections (group) {
      this.$router.push({
        name: 'groups.connections',
        params: {
          id: group.id,
          type: 'user'
        }
      })
    },

    getOtherGroups (item) {
      const groupPrograms = item.groupProgram ? item.groupProgram.length : 0
      const groupSolutions = item.groupSolution ? item.groupSolution.length : 0
      const groupLearningPaths = item.groupLearningPath ? item.groupLearningPath.length : 0
      const groupThematicPages = item.groupThematicPage ? item.groupThematicPage.length : 0

      return (groupPrograms + groupSolutions + groupLearningPaths + groupThematicPages)
    },

    openFilterModal () {
      this.filterModalOpen = !this.filterModalOpen
    },

    closeFilterModal () {
      this.filterModalOpen = false
    },

    openDeactivateGroupModal () {
      this.modalConfirmDeactivateGroupActive = true
    },

    closeDeactivateGroupModal () {
      this.modalConfirmDeactivateGroupActive = false
    },

    filterGroupList () {
      this.closeFilterModal()
      this.pagination.page = 1
      this.getGroupList()
    },

    clearFilters () {
      this.pagination.filter = {}
      this.filterGroupList()
    },

    selectFilter (value) {
      if (value) {
        this.pagination.filter.active = value
      } else {
        this.pagination.filter = {}
      }
    }
  }
}
</script>

<template>
  <div class="main-content groups-list">
    <ContentHeader
      :title="$t('groups:header.title')"
      :description="$t('groups:header.description')"
      background="/assets/images/themes/default/png/users_banner.png"
    />

    <FilterList>
      <Action
        v-if="canWrite"
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('groups.list:btn.add')"
        @click="createNewGroup"
      />

      <div
        slot="button"
        class="filter-dropdown"
      >
        <Action
          v-if="canWrite"
          flat
          fixed-width
          type="button"
          :class="{'has-value': pagination.filter.active}"
          :text="$t('sessions.list:btn.filter')"
          @click="openFilterModal"
        />
      </div>

      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchGroup"
      />
    </FilterList>

    <div
      v-if="groupsList.length > 0"
      class="center groups-table"
    >
      <Datatable :items="groupsList">
        <template slot="thead">
          <tr>
            <th
              class="th"
              width="200"
            >
              {{ $t('groups.list:datatable.header.1') }}
            </th>

            <th
              class="th text-center"
              width="100"
            >
              {{ $t('groups.list:datatable.header.2') }}
            </th>

            <th
              class="th text-center"
              width="120"
            >
              {{ $t('groups.list:datatable.header.3') }}
            </th>

            <th
              class="th text-center"
              width="120"
            >
              {{ $t('groups.list:datatable.header.4') }}
            </th>

            <th
              class="th text-center"
              width="64"
            >
              {{ $t('groups.list:datatable.header.5') }}
            </th>

            <th
              class="th"
              width="40"
            />
          </tr>
        </template>

        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.name }}</span>
            </td>

            <td class="td text-center td-description">
              <span
                v-tooltip="{
                  content: props.item.description,
                  placement: 'bottom-center',
                  classes: ['group-description-tooltip'],
                  offset: 4
                }"
                class="td-text bolder"
              >
                Ver descrição
              </span>
            </td>

            <td class="td text-center">
              <span class="td-text bolder">{{ props.item.groupUser ? props.item.groupUser.length : 0 }}</span>
            </td>

            <td class="td text-center">
              <span class="td-text bolder">{{ getOtherGroups(props.item) || 0 }}</span>
            </td>

            <td class="td text-center">
              <span
                v-if="!!props.item.status"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>

              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>

            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  v-if="canWrite"
                  :text="$t('global:edit')"
                  @click="edit(props.item.id)"
                />

                <DropdownLink
                  v-if="canWrite"
                  :text="$t('global:management')"
                  @click="openConnections(props.item)"
                />

                <DropdownLink
                  v-if="!props.item.status && canWrite"
                  :text="$t('global:activate')"
                  @click="checkGroupToggle(props.item)"
                />

                <DropdownLink
                  v-else-if="props.item.status && canWrite"
                  :text="$t('global:deactivate')"
                  @click="checkGroupToggle(props.item)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>

      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>

    <RouterView />

    <Modal
      class="modal-filters"
      :active="filterModalOpen"
      :cancel="true"
      @close="closeFilterModal"
    >
      <div class="modal-form modal-filters">
        <h2 class="modal-title">
          {{ $t('sessions.list:filter.title') }}
        </h2>

        <span class="modal-subtitle">{{ $t('sessions.list:filter.description') }}</span>

        <form @submit.prevent="filterGroupList">
          <SelectField
            v-model="pagination.filter.active"
            class="sessions-solutions-field"
            dark
            :label="$t('solutions.manage:filtered-form.input.1')"
            :items="filterOptions"
            @input="selectFilter"
          />

          <div class="form-buttons">
            <Action
              primary
              large
              dark
              fixed-width
              type="button"
              :text="$t('sessions.list:btn.clear.filter.action')"
              @click="clearFilters"
            />

            <Action
              secondary
              large
              dark
              submit
              fixed-width
              type="button"
              :text="$t('sessions.list:btn.filter.action')"
            />
          </div>
        </form>
      </div>
    </Modal>

    <ModalConfirm
      :active="modalConfirmDeactivateGroupActive"
      :title="$t('groups.connections:deactivate.title')"
      :description="$t('groups.connections:deactivate.description')"
      has-multiple-paragraphs
      @confirmAction="toggleActive"
      @cancelAction="closeDeactivateGroupModal"
    />
  </div>
</template>

<style>
.groups-list .td-description span {
  transition: opacity 120ms ease;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.groups-list .td-description span:hover {
  opacity: 0.8;
}

.groups-list .filter-dropdown {
  display: inline-flex;
  position: relative;
}

.groups-list .filter-dropdown button.has-value .text {
  text-decoration: underline;
}

.group-description-tooltip.tooltip {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  background: #005EB8;
  display: inline-flex;
  position: relative;
  padding: 12px 16px;
  border-radius: 5px;
  line-height: 1.8em;
  font-size: 0.88em;
  max-width: 200px;
  color: white;
}

.group-description-tooltip.tooltip .tooltip-inner {
  background: transparent;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 1.1em;
  display: flex;
  padding: 0;
  gap: 8px;
}

.group-description-tooltip.tooltip .tooltip-inner p {
  margin-top: 0;
}

.modal-filters .form-buttons {
  justify-content: space-between;
  display: flex;
}
</style>
